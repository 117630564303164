import Experiments from '@wix/wix-experiments';
import { parseInstance } from '@wix/communities-forum-client-commons/dist/src/services/parse-instance';

const FORUM_SCOPE = 'wix-forum';

export const getExperiments = async ({ sdk, appToken, conductBySiteOwner = false }) => {
  const config = { scope: FORUM_SCOPE };
  if (conductBySiteOwner) {
    const { siteOwnerId } = parseInstance(await sdk.document.info.getAppInstance(appToken));
    config.requestContext = { loggedInUserId: siteOwnerId, siteOwnerId };
  }
  return new Experiments(config);
};
