import { WIX_FORUM as forumAppDefId } from '@wix/app-definition-ids';

const FORUM_WIDGET_ID = '1489040e-001f-4631-55a9-2c29b4417126';

export const setForumStyles = async ({ appToken, sdk, styleParams }) => {
  const forumAppData = await sdk.tpa.app.getDataByAppDefId(appToken, forumAppDefId);
  const forumAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(appToken, forumAppData.applicationId);
  const forumAppComponent = forumAppComponents.find((component) => component.widgetId === FORUM_WIDGET_ID);

  if (!forumAppComponent) return;

  const forumComponentRef = await sdk.components.getById(appToken, { id: forumAppComponent.id });

  await sdk.tpa.setStyleParams(appToken, {
    compRef: forumComponentRef,
    styleParams,
  });
};

export const setViewCountDisabledADI = async ({ appToken, sdk, styleParams }) => {
  const forumAppData = await sdk.tpa.app.getDataByAppDefId(appToken, forumAppDefId);
  const forumAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(appToken, forumAppData.applicationId);

  const forumAppComponent = forumAppComponents.find((component) => component.widgetId === FORUM_WIDGET_ID);

  if (!forumAppComponent) return;

  const forumComponentRef = await sdk.components.getById(appToken, { id: forumAppComponent.id });

  const getParams = () =>
    sdk.tpa.getStyleParams(appToken, {
      compRef: forumComponentRef,
    });

  const setParams = () =>
    sdk.tpa.setStyleParams(appToken, {
      compRef: forumComponentRef,
      styleParams,
    });

  const validateParams = (styles) =>
    new Promise((resolve, reject) =>
      styles.booleans['site-isViewsCountEnabled'] === false && Object.keys(styles.colors).length !== 0
        ? resolve()
        : reject()
    );

  const sleep = (timeout) => new Promise((resolve) => setTimeout(resolve, timeout));

  const tryToSetParamsUntilTimeout = (retries = 10, timeout = 300) => {
    if (retries) {
      return setParams()
        .then(getParams)
        .then(validateParams)
        .catch(sleep(timeout).then(() => tryToSetParamsUntilTimeout(retries - 1, timeout * 2)));
    }
  };

  tryToSetParamsUntilTimeout();
};
