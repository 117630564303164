import { addApplications, withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';
import {
  WIX_FORUM as forumAppDefId,
  MEMBERS_AREA as membersAppDefId,
  PRICING_PLANS as pricingPlansAppDefId,
} from '@wix/app-definition-ids';
import { EXPERIMENT_VIEW_COUNTERS_DISABLED_DEFAULT } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { getExperiments } from '../services/experiments';
import { setForumStyles, setViewCountDisabledADI } from '../services/styles';

let appToken;
let sdk;

const addCommentsPage = () => addApplications([MA_APP_IDS.FORUM_COMMENTS]);

const upgradeForum = async () => {
  const instance = await sdk.info.getAppInstance({
    appDefinitionId: forumAppDefId,
  });

  try {
    // eslint-disable-next-line
    await fetch('https://forums.wix.com/_api/forums/this/upgrade?instance=' + instance, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    console.error('upgradeForum()', error);
  }
};

const installCommentsPageIfNeeded = async (sdk) => {
  const [isPostsPageInstalled, isCommentsPageInstalled] = await Promise.all([
    sdk.tpa.isAppSectionInstalled('forum-posts', {
      appDefinitionId: forumAppDefId,
      sectionId: 'member-posts-page',
    }),
    sdk.tpa.isAppSectionInstalled('forum-comments', {
      appDefinitionId: forumAppDefId,
      sectionId: 'member-comments-page',
    }),
  ]);

  if (isPostsPageInstalled && !isCommentsPageInstalled) {
    await addCommentsPage();
    return sdk.document.save().catch(() => {});
  }
};

const handleMigrate = () =>
  sdk.document.tpa.add.application(appToken, {
    appDefinitionId: pricingPlansAppDefId,
  });

const editorReady = async (_editorSDK, _appToken, options) => {
  appToken = _appToken;
  sdk = _editorSDK;
  await installCommentsPageIfNeeded(sdk);
  const isAdi = options.origin.type === 'ADI' || options.origin.type === 'ADI_MA';
  const experiments = await getExperiments({ sdk, appToken, conductBySiteOwner: true });
  const isViewCountersDisabledDefaultEnabled =
    (await experiments.conduct(EXPERIMENT_VIEW_COUNTERS_DISABLED_DEFAULT, false)) === 'true';

  if (options.firstInstall && isViewCountersDisabledDefaultEnabled) {
    const styleParams = [{ type: 'boolean', key: 'site-isViewsCountEnabled', param: { value: false } }];
    isAdi
      ? await setViewCountDisabledADI({ sdk, appToken, styleParams })
      : await setForumStyles({ sdk, appToken, styleParams });
  }
};

const handleAction = async (args) => {
  const type = args.type;
  const payload = args.payload;

  try {
    switch (type) {
      case 'appInstalled':
        switch (payload.appDefinitionId) {
          case forumAppDefId: {
            const [forumAppData = {}, allPages = []] = await Promise.all([
              sdk.tpa.app.getDataByAppDefId(appToken, forumAppDefId),
              sdk.pages.data.getAll(appToken),
            ]);

            const forumPage = allPages.find(
              (page) => page.tpaPageId === 'forum' && page.tpaApplicationId === forumAppData.applicationId
            );

            await (forumPage &&
              sdk.document.pages.navigateTo(appToken, {
                pageLink: {
                  type: 'PageLink',
                  pageId: forumPage.id,
                },
              }));
            return sdk.document.save().catch(function () {});
          }
          case membersAppDefId: {
            const isForumInstalled = await sdk.tpa.isApplicationInstalled(appToken, {
              appDefinitionId: forumAppDefId,
            });

            if (isForumInstalled) {
              upgradeForum();
            }
          }
          // eslint-disable-next-line no-fallthrough
          default:
            return Promise.resolve();
        }

      case 'migrate':
        return handleMigrate(payload);

      default:
        console.log(type, payload);
        return Promise.resolve();
    }
  } catch (e) {
    Promise.reject();
    throw e;
  }
};

export const editorScript = withMembersArea(
  {
    editorReady,
    handleAction,
  },
  {
    disableADI: false,
    membersAreaApps: [
      MA_APP_IDS.ABOUT,
      MA_APP_IDS.ALL_MEMBERS,
      MA_APP_IDS.FOLLOWERS,
      MA_APP_IDS.FORUM_COMMENTS,
      MA_APP_IDS.FORUM_POSTS,
      MA_APP_IDS.NOTIFICATIONS,
      MA_APP_IDS.SETTINGS,
    ],
  }
);
